import React, { useEffect, useState } from 'react'
import { Typography, Box, Button, Select, MenuItem } from '@material-ui/core'
import { SectionHeaderSmall } from '../../theme/typography'
import FreeTrialBanner from './FreeTrialBanner'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'proptypes'
import styled from 'styled-components'
const StyledListBox = styled(Box)(({ theme, tick }) => ({
  fontSize: '0.875rem',
  textAlign: 'left',
  '& ul': {
    listStyle: 'none',
    padding: theme.spacing(3),
    marginTop: 0,
    marginBottom: 0
  },
  '& li': {
    position: 'relative',
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(3.5),
    '&::after': {
      content: tick ? '""' : '"•"',
      position: 'absolute',
      top: 9,
      left: 0,
      height: tick ? 15 : 0,
      width: tick ? 7 : 0,
      borderRight: tick ? `3px solid currentColor` : 'none',
      borderBottom: tick ? `3px solid currentColor` : 'none',
      transform: tick ? 'translateY(-50%) rotate(45deg)' : 'none',
      zIndex: 1
    }
  }
}))
const Badge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: -20,
  top: -20,
  width: 72,
  height: 72,
  lineHeight: 1,
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontWeight: 'bold',
  boxShadow: theme.shadows[2],
  backgroundColor: theme.colors.pink,
  color: theme.colors.white,
  borderRadius: '50%',
  transform: 'rotate(20deg)',
  zIindex: 1,
  fontSize: '1.25rem',
  flexShrink: 0,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 6,
    left: 6,
    border: `1px dashed ${theme.colors.white}`,
    width: 60,
    height: 60,
    borderRadius: '50%'
  },
  '& .f-14': {
    fontSize: '0.875rem'
  }
}))

const PlansWrap = styled(Box)(() => ({
  maxWidth: 585,
  marginLeft: 'auto',
  marginRight: 'auto'
}))
const PlansWrapBox = styled(Box)(({ theme }) => ({
  marginLeft: -theme.spacing(1),
  marginRight: -theme.spacing(1),
  flexWrap: 'nowrap',
  alignItems: 'center',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column'
  }
}))

const PlanBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.white,
  position: 'relative',
  flex: '1 1 260px',
  display: 'flex',
  flexDirection: 'column',
  margin: '12px 8px',
  borderRadius: 4,
  boxShadow: theme.shadows[2],
  padding: theme.spacing(3, 2, 2),
  cursor: 'pointer',
  transform: 'scale(1)',
  transition: 'transform .3s cubic-bezier(0.22, 0.61, 0.36, 1)',
  '& .plans-button': {
    transition: 'backgroundColor .3s cubic-bezier(0.22, 0.61, 0.36, 1)'
  },
  [theme.breakpoints.down('xs')]: {
    maxWidth: 280,
    flex: '1 1 auto'
  },
  '&:hover': {
    transform: 'scale(1.05)',
    color: theme.colors.white,
    backgroundImage: 'linear-gradient(142deg, #16bce6, #299dc1)',
    boxShadow: '2px 2px 11px 0 rgb(41 157 193 / 30%), 0 1px 3px 0 rgb(41 157 193 / 30%)',
    '& .plans-button': {
      backgroundImage: `linear-gradient(142deg, ${theme.colors.white}, ${theme.colors.white})`,
      backgroundColor: theme.colors.white,
      color: theme.colors.blue1
    }
  }
}))
const TitleTop = styled(SectionHeaderSmall)(({ theme }) => ({
  marginBottom: theme.spacing(0.2)
}))

const SelectStyled = styled(Select)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  backgroundColor: theme.colors.white,
  minHeight: 44,
  borderRadius: 4,
  boxShadow: theme.shadows[2],
  [theme.breakpoints.down('xs')]: {
    maxWidth: 280
  },
  '& > div > li': {
    width: '100%'
  },
  '& fieldset': {
    border: 'none'
  },
  '& > svg': {
    fontSize: '2rem',
    opacity: 0.7,
    top: 'calc(50% - 15px)',
    padding: 0
  },
  '& > div': {
    outline: 'none',
    minHeight: 'inherit',
    display: 'inline-flex',
    padding: 0,
    paddingRight: 0 + 'px !important'
  }
}))

const Flag = styled('img')(({ theme }) => ({
  width: 24,
  display: 'block',
  boxShadow: theme.shadows[1],
  marginRight: theme.spacing(2)
}))

const CountrySelector = (props) => {
  const [selectedCountry, setSelectedCountry] = useState(props.selectedCountry)

  return (
    <SelectStyled
      id="select-country"
      select
      displayEmpty
      IconComponent={ExpandMoreIcon}
      onChange={(e) => {
        setSelectedCountry(e.target.value)
        props.selectedCountryChanged(e.target.value)
      }}
      variant="outlined"
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        getContentAnchorEl: null
      }}
      renderValue={() => {
        return (
          <MenuItem key={selectedCountry.iso} value={selectedCountry.name}>
            <Flag
              src={`../../../images/flags/${selectedCountry.iso.toLowerCase()}.svg`}
              alt={selectedCountry.iso}
            />
            {selectedCountry.name}
          </MenuItem>
        )
      }}
      fullWidth
    >
      {!selectedCountry && (
        <MenuItem key="default" value="" disabled>
          {props.placeholder}
        </MenuItem>
      )}

      {props.countries.map((country) => {
        return (
          <MenuItem key={country.iso} value={country}>
            <Flag
              src={`../../../images/flags/${country.iso.toLowerCase()}.svg`}
              alt={country.iso}
            />
            {country.name}
          </MenuItem>
        )
      })}
    </SelectStyled>
  )
}

const PromotionalBadge = (props) => {
  if (!props.promoPricingTemplate) {
    return <></>
  }

  const placeholder = '{saving}'
  const indexOf = props.promoPricingTemplate.indexOf(placeholder)

  if (indexOf !== -1) {
    const firstPart = props.promoPricingTemplate.substring(0, indexOf)
    const lastPart = props.promoPricingTemplate.substring(indexOf + placeholder.length)

    return (
      <Badge>
        {firstPart && <span className="f-14">{firstPart}</span>}
        {props.saving}%{lastPart && <span className="f-14">{lastPart}</span>}
      </Badge>
    )
  } else {
    return (
      <Badge>
        <span className="f-14">{props.promoPricingTemplate}</span>
      </Badge>
    )
  }
}

const PlanPrice = (props) => {
  const [price, setPrice] = useState()
  useEffect(() => {
    setPrice(
      new Intl.NumberFormat(navigator.language || 'en-US', {
        style: 'currency',
        currency: props.planCurrency,
        minimumFractionDigits: props.planPrice % 1 > 0 ? 2 : 0
      }).format(props.planPrice)
    )
  }, [props.planPrice, props.planCurrency])

  const placeholder = '{amount}'
  const indexOf = props.planPriceTemplate.indexOf(placeholder)
  const priceLabel =
    indexOf !== -1 ? (
      <span>
        {props.planPriceTemplate.substring(0, indexOf)}
        <strong>{price}</strong>
        {props.planPriceTemplate.substring(indexOf + placeholder.length)}
      </span>
    ) : (
      <span>{props.planPriceTemplate}</span>
    )

  return <Typography variant="h3">{priceLabel}</Typography>
}

const Plan = (props) => {
  const buyUrl = decodeURIComponent(props.selectLink)
    .replace('{duration}', props.duration)
    .replace('{iso}', props.selectedPlan.iso)

  return (
    <PlanBox
      data-ads-event="select-plan"
      onClick={() => {
        if (!props.disabled) {
          window.open(buyUrl, '_blank')
        }
      }}
    >
      {props.promoPricingTemplate && (
        <PromotionalBadge
          saving={props.promoPrice}
          promoPricingTemplate={props.promoPricingTemplate}
        />
      )}
      <TitleTop component="h4" gutterBottom>
        {props.planDuration}
      </TitleTop>
      <PlanPrice {...props} />

      <StyledListBox tick={true}>
        <ul>
          {props.planFeatures &&
            props.planFeatures.map((feature, i) => {
              if (i === 0) {
                return (
                  <li key={i}>
                    <b>
                      <i>{feature}</i>
                    </b>
                  </li>
                )
              }
              return <li key={i}>{feature}</li>
            })}
        </ul>
      </StyledListBox>

      {!props.disabled && (
        <>
          <Button variant="contained" color="primary" className="plans-button">
            {props.selectText}
          </Button>
        </>
      )}
    </PlanBox>
  )
}
function PlansComponent(props) {
  const countries = props.plans.map((plan) => {
    return { iso: plan.iso, name: plan.name }
  })
  const defaultCountry = countries.find((country) => country.iso === props.defaultCountry)
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry)
  const selectedPlan = props.plans.find(
    (plan) => selectedCountry !== null && plan.iso === selectedCountry.iso
  )

  const yearlyAmountForMonthly = 12 * selectedPlan.monthlyPrice
  const promoPrice = Math.round(
    ((yearlyAmountForMonthly - selectedPlan.yearlyPrice) / yearlyAmountForMonthly) * 100
  )

  return (
    <PlansWrap>
      <CountrySelector
        countries={countries}
        placeholder={props.placeholder}
        selectedCountry={selectedCountry}
        selectedCountryChanged={setSelectedCountry}
      />

      {selectedPlan && (
        <PlansWrapBox display="flex" flexWrap="wrap">
          <Plan
            id="monthly"
            disabled={props.disablePlanLinks}
            planDuration={props.monthlySubscription}
            planPriceTemplate={props.perMonthPrice}
            planPrice={selectedPlan.monthlyPrice}
            planCurrency={selectedPlan.currency}
            planFeatures={props.planFeatures}
            selectText={props.selectText}
            selectLink={props.selectLink}
            duration="MONTH"
            selectedPlan={selectedPlan}
          />

          {selectedPlan.yearlyPrice && (
            <Plan
              id="yearly"
              disabled={props.disablePlanLinks}
              planDuration={props.yearlySubscription}
              planPriceTemplate={props.perYearPrice}
              planPrice={selectedPlan.yearlyPrice}
              planCurrency={selectedPlan.currency}
              planFeatures={props.planFeatures}
              selectText={props.selectText}
              selectLink={props.selectLink}
              promoPrice={promoPrice}
              promoPricingTemplate={props.promoPricingTemplate}
              duration="YEAR"
              selectedPlan={selectedPlan}
            />
          )}
        </PlansWrapBox>
      )}

      {props.disclaimerText && (
        <div
          className="tracking-wide mt-5 pb-5 text-xs text-navy opacity-60"
          dangerouslySetInnerHTML={{
            __html: props.disclaimerText
          }}
        />
      )}

      <FreeTrialBanner
        freeTrialBackground={props.freeTrialBackground}
        freeTrialHeading={props.freeTrialHeading}
        freeTrialText={props.freeTrialText}
        freeTrialBadgeText={props.freeTrialBadgeText}
        freeTrialLinkUrl={props.freeTrialLinkUrl}
        freeTrialFreeText={props.freeTrialFreeText}
        freeTrialImage={props.freeTrialImage}
      />
    </PlansWrap>
  )
}

export default PlansComponent

PlansComponent.propTypes = {
  titleTop: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  defaultCountry: PropTypes.string,
  plans: PropTypes.array,
  planFeatures: PropTypes.array,
  placeholder: PropTypes.string,
  selectText: PropTypes.string,
  monthlySubscription: PropTypes.string,
  yearlySubscription: PropTypes.string,
  perMonthPrice: PropTypes.string,
  perYearPrice: PropTypes.string,
  promoPricingTemplate: PropTypes.string,
  freeTrialText: PropTypes.string,
  freeTrialHeading: PropTypes.string,
  freeTrialBadgeText: PropTypes.string,
  freeTrialLinkUrl: PropTypes.string,
  freeTrialBackground: PropTypes.string,
  freeTrialFreeText: PropTypes.string
}
