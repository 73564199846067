import React from 'react'
import { Container, Typography, Box, makeStyles } from '@material-ui/core'
import { TitleH2Bold, SectionHeaderSmall } from '../../theme/typography'
import PlansComponent from './PlansComponent'
import PropTypes from 'proptypes'
import styled from 'styled-components'
import plansJson from '../../data/watch/plans.json'
import Svg from 'react-inlinesvg'
import WorldLeft from '../../images/bgimages/images-world-map-left.svg'
import WorldRight from '../../images/bgimages/images-world-map-right.svg'

const useStyles = makeStyles((theme) => ({
  worldMapLeft: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute !important',
      top: 50,
      left: -250
    },
    '@media (min-width: 1400px)': {
      left: -100
    },
    '@media (min-width: 1700px)': {
      left: 0
    }
  },
  worldMapRight: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute !important',
      top: 50,
      right: -320
    },
    [theme.breakpoints.up('lg')]: {
      right: -250
    },
    '@media (min-width: 1400px)': {
      right: -150
    },
    '@media (min-width: 1700px)': {
      right: 0
    }
  }
}))

const TitleTop = styled(SectionHeaderSmall)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}))
const ContentText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5)
}))
const MainContainer = styled(Container)(() => ({
  position: 'relative'
}))

function WatchPlans(props) {
  const classes = useStyles(props)
  const countriesNames = require(`../../data/country/countries-regions-${props.locale}.json`).map(
    (translation) => {
      return {
        iso: translation.ISO,
        name: translation['Country Name'],
        region: translation.region
      }
    }
  )
  const plansItems = plansJson

  const translatedPlans = plansItems
    .map((plan) => {
      let translation = countriesNames.find((translation) => translation.iso === plan.iso)
      return {
        iso: plan.iso,
        name: translation ? translation.name : '',
        currency: plan.currency,
        monthlyPrice: plan.monthlyPrice,
        yearlyPrice: plan.yearlyPrice
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name))

  return (
    <Box pt={7} pb={props.watch ? 0 : 9} style={{ backgroundColor: '#f7f6f6' }}>
      <MainContainer maxWidth="md" align="center">
        <TitleTop component="h1" gutterBottom>
          {props.titleTop}
        </TitleTop>
        <TitleH2Bold component="h2" gutterBottom>
          {props.title}
        </TitleH2Bold>
        <ContentText variant="body1" color="textSecondary">
          {props.content}{' '}
        </ContentText>
        {translatedPlans && <PlansComponent {...props} plans={translatedPlans} />}
      </MainContainer>
      <Svg className={classes.worldMapLeft} src={WorldLeft} alt="" />
      <Svg className={classes.worldMapRight} src={WorldRight} alt="" />
    </Box>
  )
}

export default WatchPlans

WatchPlans.propTypes = {
  title: PropTypes.string,
  topText: PropTypes.string,
  content: PropTypes.string,
  plans: PropTypes.arrayOf(PropTypes.shape({}))
}

WatchPlans.defaultProps = {
  plans: []
}
