import React from 'react'
import { Container, Button, Grid, Box, Typography } from '@material-ui/core'
import { TitleH3Bold } from '../../theme/typography'
import CustomButton from '../../components/Button/CustomButton'
import PropTypes from 'proptypes'
import styled from 'styled-components'
import { placeImage } from '../../utils'

const ImageBox = styled(Box)(({ theme }) => ({
  height: 120,
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    height: 'auto'
  }
}))
const WrapContainer = styled(Container)({
  maxWidth: 1200
})
const WrapBox = styled(Box)(({ theme, color, bgcolor, ipad, watch }) => ({
  position: 'relative',
  zIndex: 1,
  color: color === 'white' ? theme.colors.white : theme.colors.blue5,
  backgroundColor: bgcolor && !ipad ? bgcolor : 'transparent',
  paddingTop: ipad || watch ? theme.spacing(10) : theme.spacing(17),
  paddingBottom: theme.spacing(10),
  textAlign: 'center',
  '@media (max-width: 1959px)': {
    backgroundColor: bgcolor ? bgcolor : 'transparent'
  },
  '@media (max-width: 959px)': {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: 'transparent',
    backgroundImage:
      color === 'white'
        ? `linear-gradient(180deg, transparent 30%, ${theme.colors.blue7} 30%, ${theme.colors.blue7})`
        : 'transparent'
  },
  '@media (max-width: 400px)': {
    backgroundImage:
      color === 'white'
        ? `linear-gradient(180deg, transparent 15%, ${theme.colors.blue7} 15%, ${theme.colors.blue7})`
        : 'transparent'
  }
}))
const GridItem = styled(Grid)(({ theme, color }) => ({
  color: 'inherit',
  '& p > a': {
    color: color === 'white' ? theme.colors.white : theme.colors.blue1,
    textDecoration: 'none',
    fontWeight: 'bold',
    '&:hover': {
      color: color === 'white' ? theme.colors.white : '#67b2ce'
    }
  }
}))

function TwoBlocks(props) {
  return (
    <WrapBox color={props.color} bgcolor={props.bgcolor} ipad={props.ipad} watch={props.watch}>
      <WrapContainer maxWidth="lg">
        <Grid container spacing={8}>
          {props.leftIconTextBlock && (
            <GridItem item xs={12} md={6} color={props.color}>
              <ImageBox>
                {props.leftIconTextBlock.imageSrc && placeImage(props.leftIconTextBlock.imageSrc)}
              </ImageBox>

              <TitleH3Bold component="h2" gutterBottom>
                {props.leftIconTextBlock.title}
              </TitleH3Bold>
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                dangerouslySetInnerHTML={{ __html: props.leftIconTextBlock.description }}
              />
              <Box mt={3}>
                {props.color === 'white' ? (
                  <CustomButton
                    color="white"
                    variant="contained"
                    href={props.leftIconTextBlock.cta.href}
                    target={props.leftIconTextBlock.cta.openIn}
                    rel={props.leftIconTextBlock.cta.rel}
                    title={props.leftIconTextBlock.cta.title}
                  >
                    {props.leftIconTextBlock.cta.text}
                  </CustomButton>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    href={props.leftIconTextBlock.cta.href}
                    target={props.leftIconTextBlock.cta.openIn}
                    rel={props.leftIconTextBlock.cta.rel}
                    title={props.leftIconTextBlock.cta.title}
                  >
                    {props.leftIconTextBlock.cta.text}
                  </Button>
                )}
              </Box>
            </GridItem>
          )}

          {props.rightIconTextBlock && (
            <GridItem item xs={12} md={6} color={props.color}>
              <ImageBox>
                {props.rightIconTextBlock.imageSrc && placeImage(props.rightIconTextBlock.imageSrc)}
              </ImageBox>

              <TitleH3Bold component="h2" gutterBottom>
                {props.rightIconTextBlock.title}
              </TitleH3Bold>
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                dangerouslySetInnerHTML={{ __html: props.rightIconTextBlock.description }}
              />
              <Box mt={3}>
                {props.color === 'white' ? (
                  <CustomButton
                    color="white"
                    variant="contained"
                    href={props.rightIconTextBlock.cta.href}
                    target={props.rightIconTextBlock.cta.openIn}
                    rel={props.rightIconTextBlock.cta.rel}
                    title={props.rightIconTextBlock.cta.title}
                  >
                    {props.rightIconTextBlock.cta.text}
                  </CustomButton>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    href={props.rightIconTextBlock.cta.href}
                    target={props.rightIconTextBlock.cta.openIn}
                    rel={props.rightIconTextBlock.cta.rel}
                    title={props.rightIconTextBlock.cta.title}
                  >
                    {props.rightIconTextBlock.cta.text}
                  </Button>
                )}
              </Box>
            </GridItem>
          )}
        </Grid>
      </WrapContainer>
    </WrapBox>
  )
}

export default TwoBlocks

TwoBlocks.propTypes = {
  leftIconTextBlock: PropTypes.shape({
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
      rel: PropTypes.string,
      target: PropTypes.string,
      title: PropTypes.string
    })
  }),
  rightIconTextBlock: PropTypes.shape({
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
      rel: PropTypes.string,
      target: PropTypes.string,
      title: PropTypes.string
    })
  })
}

TwoBlocks.defaultProps = {
  list: []
}
