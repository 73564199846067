import React, { useState, useEffect } from 'react'
import ConsumerLayout from '../../components/Layouts/ConsumerLayout'
import Hero from '../../components/Banners/Hero'
import ItemCarousel from '../../components/Consumer/ItemCarousel'
import WatchPlans from '../../components/Consumer/WatchPlans'
import DevicesCarousel from '../../components/Consumer/DevicesCarousel'
import ImageTextBlock from '../../components/Other/ImageTextBlock'
import FloatingBlock from '../../components/Consumer/FloatingBlock'
import HeroConsumer from '../../components/Consumer/HeroConsumer'
import TwoBlocks from '../../components/Consumer/TwoBlocks'
import { ParallaxProvider } from 'react-scroll-parallax'
import { makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
import FAQList from '../../components/Other/FAQList'
import { Experiment, Variant, emitter, experimentDebugger } from '@marvelapp/react-ab-test'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Svg from 'react-inlinesvg'
import CombinedBlue2Large from '../../images/bgimages/combined-blue2-large.svg'
import CombinedBlue2a from '../../images/bgimages/combined-blue2a.svg'
import SquircleCombinedBlue from '../../images/bgimages/squircle-combined-blue.svg'

const variants = ['A', 'B']
emitter.defineVariants('esim-for-watch', variants)

const useStyles = makeStyles((theme) => ({
  gradient: {
    position: 'relative',
    overflow: 'hidden',
    backgroundImage:
      'linear-gradient(to bottom, #299dc1, rgba(41, 157, 193, 0.8) 50%, rgba(41, 157, 193, 0.6))',
    padding: '3rem 0 0'
  },
  devicesCarouselBgImage: {
    position: 'absolute !important',
    zIndex: 0,
    bottom: '-75%',
    width: 1370,
    left: '58%',
    transform: 'translateX(-50%)'
  },
  devicesCarouselBottom: {
    position: 'relative',
    backgroundColor: '#f7f6f6',
    height: '7rem',
    padding: '3rem 0 0'
  },
  overflow: {
    overflow: 'hidden'
  },
  contentWrap: {
    position: 'relative',
    overflow: 'hidden',
    paddingTop: 40
  },
  bgGrey: {
    backgroundColor: '#f7f6f6',
    position: 'relative'
  },
  bgGreyGradient: {
    backgroundImage: 'linear-gradient(to bottom, #f7f6f6, #ffffff)'
  },
  circle1: {
    position: 'absolute',
    left: -600,
    bottom: 180,
    '@media (min-width: 960px)': {
      left: -450
    }
  },
  circle2: {
    position: 'absolute',
    right: -600,
    bottom: 480,
    '@media (min-width: 960px)': {
      right: -450
    }
  },
  bgBlueCircles: {
    position: 'absolute !important',
    left: -500,
    bottom: 0,
    maxWidth: 'none',
    width: 1600,
    '@media (min-width: 960px)': {
      left: -500,
      width: 2100,
      bottom: -750
    },
    '@media (min-width: 1350px)': {
      left: -300
    },
    '@media (min-width: 1800px)': {
      left: '-11vw',
      width: '120vw'
    }
  },
  twoItems: {
    position: 'relative'
  },
  phoneBgImage: {
    position: 'absolute !important',
    right: 100,
    top: -50,
    width: 100,
    display: 'none',
    opacity: 0.9,
    [theme.breakpoints.up('md')]: {
      display: 'block',
      bottom: 'auto'
    }
  },
  phoneBgImage2: {
    position: 'absolute !important',
    right: -108,
    bottom: 50,
    width: 350,
    height: 340,
    display: 'none',
    '& > div': {
      maxWidth: 350
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      bottom: 'auto',
      top: 100
    }
  },
  phoneBgImage3: {
    position: 'absolute !important',
    left: -100,
    top: 150,
    width: 350,
    height: 340,
    display: 'none',
    '& > div': {
      maxWidth: 350
    },
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  }
}))

function VersionA(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.contentWrap}>
      <HeroConsumer
        watchA={true}
        bgcolor="gradient"
        bgimage={'../../images/bgimages/apple-watch-bg.svg'}
        image={props.heroImage}
        topText={props.heroTitle}
        title={props.heroSubtitle}
        content={props.heroContent}
        videoUrl={props.heroVideoLink}
        ctas={[
          {
            text: props.heroPrimaryCtaText,
            href: props.heroPrimaryCtaLink,
            target: props.heroPrimaryCtaOpenNewTab
          },
          {
            text: props.heroSecondaryCtaText,
            href: props.heroSecondaryCtaLink,
            target: props.heroSecondaryCtaOpenNewTab
          }
        ]}
      />
      {props.howItWorksSlides && props.howItWorksSlides.length > 0 && (
        <div className={classes.gradient}>
          <Svg
            src={CombinedBlue2Large}
            alt="bg-circle-blue-large"
            className={classes.devicesCarouselBgImage}
          />
          <DevicesCarousel
            modalTitle={props.eidModalTitle}
            modalContent={props.eidModalContent}
            modalCloseText={props.eidModalButtonText}
            minHeight={350}
            watch={true}
            textBottom={props.howItWorksFooterLink}
            list={props.howItWorksSlides.map((slide) => {
              return {
                topTitle: slide.topTitle,
                title: slide.title,
                content: slide.content,
                image: slide.image,
                ctas: [
                  {
                    text: slide.primaryCtaText,
                    href: slide.primaryCtaLink,
                    openNewTab: slide.primaryCtaOpenTab
                  },
                  {
                    text: slide.secondaryCtaText,
                    href: slide.secondaryCtaLink,
                    openNewTab: slide.secondaryCtaOpenTab
                  }
                ]
              }
            })}
          />
          <div className={classes.devicesCarouselBottom}></div>
        </div>
      )}
      <div className={classes.overflow} id={props.plansAnchor}>
        <div className={classes.bgGrey}>
          <WatchPlans
            locale={props.locale}
            id={props.plansAnchor}
            titleTop={props.plansTitleTop}
            title={props.plansTitle}
            content={props.plansContent}
            defaultCountry={props.plansDefaultCountry}
            planFeatures={JSON.parse(props.plansPlanFeatures)}
            placeholder={props.plansPlaceholder}
            selectText={props.plansSelectText}
            selectLink={props.plansSelectLink}
            monthlySubscription={props.plansMonthlySubscription}
            yearlySubscription={props.plansYearlySubscription}
            perMonthPrice={props.plansPerMonthPrice}
            perYearPrice={props.plansPerYearPrice}
            promoPricingTemplate={props.plansPromoPricingText}
            disclaimerText={props.plansDisclaimerTextNode?.childMarkdownRemark.html}
            freeTrialText={props.plansFreeTrialText}
            freeTrialHeading={props.plansFreeTrialHeading}
            freeTrialBadgeText={props.plansFreeTrialBadgeText}
            freeTrialLinkUrl={props.plansFreeTrialLinkUrl}
            freeTrialBackground={props.plansFreeTrialBackground}
            freeTrialFreeText={props.plansFreeTrialFreeText}
            freeTrialImage={props.plansFreeTrialImage}
          />
          <div style={{ position: 'relative' }}>
            <Svg className={classes.phoneBgImage} src={CombinedBlue2a} alt="bg-circles" />
            <Svg className={classes.phoneBgImage2} src={CombinedBlue2a} alt="bg-circles" />
            <Svg className={classes.phoneBgImage3} src={CombinedBlue2a} alt="bg-circles" />
            {props.cardCarouselCards && props.cardCarouselCards.length > 0 && (
              <ItemCarousel
                bgcolor="transparent"
                type="bluebox"
                title={props.cardCarouselTitle}
                content={props.cardCarouselDescription}
                list={props.cardCarouselCards.map((card) => {
                  return {
                    title: card.title,
                    content: card.description,
                    icon: card.icon
                  }
                })}
              />
            )}

            {props.twoItemIconTextAreaLeftTitle && (
              <div className={classes.twoItems}>
                <Svg
                  src={SquircleCombinedBlue}
                  alt="bg-blue-circles"
                  className={classes.bgBlueCircles}
                />

                <TwoBlocks
                  color="white"
                  watch={true}
                  leftIconTextBlock={Object.create({
                    title: props.twoItemIconTextAreaLeftTitle,
                    description: props.twoItemIconTextAreaLeftText,
                    imageSrc: props.twoItemIconTextAreaLeftIcon,
                    cta: {
                      text: props.twoItemIconTextAreaLeftCtaText,
                      href: props.twoItemIconTextAreaLeftCtaLink,
                      target: props.twoItemIconTextAreaLeftCtaOpenNewTab ? '_blank' : ''
                    }
                  })}
                  rightIconTextBlock={Object.create({
                    title: props.twoItemIconTextAreaRightTitle,
                    description: props.twoItemIconTextAreaRightText,
                    imageSrc: props.twoItemIconTextAreaRightIcon,
                    cta: {
                      text: props.twoItemIconTextAreaRightCtaText,
                      href: props.twoItemIconTextAreaRightCtaLink,
                      target: props.twoItemIconTextAreaLeftCtaOpenNewTab ? '_blank' : ''
                    }
                  })}
                />
              </div>
            )}
            {props.footerTitle && (
              <FloatingBlock
                marginBottom="-40px"
                right="auto"
                colortop="transparent"
                colorbottom="#023240"
                title={props.footerTitle}
                footerImage={props.footerImage}
                subtitle={props.footerDescription}
                ctas={[
                  {
                    text: props.footerPrimaryCtaText,
                    href: props.footerPrimaryCtaLink,
                    target: props.footerPrimaryCtaOpenNewTab
                  },
                  {
                    text: props.footerSecondaryCtaText,
                    href: props.footerSecondaryCtaLink,
                    target: props.footerSecondaryCtaOpenNewTab
                  }
                ]}
              />
            )}
            {props.contactSupportBannerTitle && (
              <Hero
                title={props.contactSupportBannerTitle}
                subtitle={props.contactSupportBannerDescription}
                ctas={[
                  {
                    text: props.contactSupportBannerCtaText,
                    href: props.contactSupportBannerCtaLink,
                    target: props.contactSupportBannerCtaOpenNewTab ? '_blank' : ''
                  }
                ]}
                bgColor="#01313f"
                color="#ffffff"
              />
            )}
          </div>
        </div>
      </div>
      {props.faqItems.length > 0 && (
        <div className={classes.bgWhite}>
          <FAQList
            feedbackEnabled={false}
            large={true}
            title={props.faqTitle}
            list={props.faqItems}
          />
        </div>
      )}
    </div>
  )
}

function VersionB(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.contentWrap}>
      <div className={classes.bgGrey}>
        <HeroConsumer
          watch={true}
          bgcolor="gradient"
          image={props.variantImage}
          topText={props.variantTitle}
          title={props.variantSubtitle}
          content={props.variantDescription}
          videoUrl={props.variantVideoLink}
          ctas={[
            {
              text: props.variantCtaText,
              href: props.variantCtaLink,
              target: props.variantCtaOpenNewTab
            }
          ]}
          freeTrialBanner={[
            {
              isHero: true,
              freeTrialText: props.variantBannerText,
              freeTrialHeading: props.variantBannerTitle,
              freeTrialLinkUrl: props.variantBannerLink,
              freeTrialBackground: props.variantBannerBackground,
              freeTrialBadgeText: props.variantBannerButtonText
            }
          ]}
        />

        {props.cardCarouselCards.length > 0 && (
          <ItemCarousel
            bgcolor="dark"
            type="bluebox"
            list={props.cardCarouselCards.map((card) => {
              return {
                title: card.title,
                content: card.description,
                icon: card.icon
              }
            })}
          />
        )}
      </div>

      <div className={classes.overflow} id={props.plansAnchor}>
        <div className={classes.bgGrey}>
          <WatchPlans
            locale={props.locale}
            id={props.plansAnchor}
            titleTop={props.plansTitleTop}
            title={props.plansTitle}
            content={props.plansContent}
            defaultCountry={props.plansDefaultCountry}
            planFeatures={JSON.parse(props.plansPlanFeatures)}
            placeholder={props.plansPlaceholder}
            selectText={props.plansSelectText}
            selectLink={props.plansSelectLink}
            monthlySubscription={props.plansMonthlySubscription}
            yearlySubscription={props.plansYearlySubscription}
            perMonthPrice={props.plansPerMonthPrice}
            perYearPrice={props.plansPerYearPrice}
            promoPricingTemplate={props.plansPromoPricingText}
            disclaimerText={props.plansDisclaimerTextNode?.childMarkdownRemark.html}
            freeTrialText={props.plansFreeTrialText}
            freeTrialHeading={props.plansFreeTrialHeading}
            freeTrialBadgeText={props.plansFreeTrialBadgeText}
            freeTrialLinkUrl={props.plansFreeTrialLinkUrl}
            freeTrialBackground={props.plansFreeTrialBackground}
            freeTrialFreeText={props.plansFreeTrialFreeText}
            freeTrialImage={props.plansFreeTrialImage}
          />
        </div>
      </div>
      {props.variantVideoSectionTitle && (
        <ParallaxProvider>
          <div className={classes.bgGreyGradient}>
            <ImageTextBlock
              textWhite={false}
              customVideoPlayIcon={'../../images/icons/arrow-play-pink.svg'}
              titleLarge={props.variantVideoSectionTitle}
              text={props.variantVideoSectionDescription}
              image={props.variantVideoSectionImage}
              bgImgColor="#a7855f"
              noPaddingTop={true}
              videoUrl={props.variantVideoSectionVideo}
            />
          </div>
        </ParallaxProvider>
      )}
      {props.faqItems.length > 0 && (
        <div className={classes.bgWhite}>
          <FAQList
            feedbackEnabled={false}
            large={true}
            title={props.faqTitle}
            list={props.faqItems}
          />
        </div>
      )}
    </div>
  )
}

function WatchPage({
  data: { datoCmsAppleWatchLandingPage, datoCmsSite },
  pageContext: { menu, locale, footer, cookiePrompt }
}) {
  experimentDebugger.enable()

  const [ready, setReady] = useState(false)

  useEffect(() => {
    let variant = 'A'
    const variantUrl = new URLSearchParams(window.location.search)
    if (variantUrl.get('variant')) {
      variant = variantUrl.get('variant').toUpperCase()
      if (variants.includes(variant)) {
        emitter.setActiveVariant('esim-for-watch', variant)
      }
    }

    setReady(true)
  }, [])

  if (!ready) {
    return <></>
  }

  return (
    <ConsumerLayout menu={menu} footer={footer} cookiePrompt={cookiePrompt}>
      <HelmetDatoCms
        seo={datoCmsAppleWatchLandingPage.seoMetaTags}
        favicon={datoCmsSite.faviconMetaTags}
      />

      {datoCmsAppleWatchLandingPage.variantEnabled && (
        <Experiment name="esim-for-watch">
          <Variant name="A">
            <VersionA locale={locale} {...datoCmsAppleWatchLandingPage} />
          </Variant>

          <Variant name="B">
            <VersionB locale={locale} {...datoCmsAppleWatchLandingPage} />
          </Variant>
        </Experiment>
      )}

      {!datoCmsAppleWatchLandingPage.variantEnabled && (
        <VersionA locale={locale} {...datoCmsAppleWatchLandingPage} />
      )}
    </ConsumerLayout>
  )
}

export default WatchPage

export const query = graphql`
  query AppleWatchLandingPage($locale: String!) {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsAppleWatchLandingPage(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroTitle
      heroSubtitle
      heroContent
      heroImage {
        url
        alt
        gatsbyImageData(height: 514)
      }
      heroVideoLink
      heroPrimaryCtaText
      heroPrimaryCtaLink
      heroPrimaryCtaOpenNewTab
      heroSecondaryCtaText
      heroSecondaryCtaLink
      heroSecondaryCtaOpenNewTab
      howItWorksSlides {
        topTitle
        title
        content
        image {
          url
          alt
          gatsbyImageData(height: 513)
        }
        primaryCtaText
        primaryCtaLink
        primaryCtaOpenNewTab
        secondaryCtaText
        secondaryCtaLink
        secondaryCtaOpenNewTab
      }
      howItWorksFooterLink
      plansAnchor
      plansTitleTop
      plansTitle
      plansContent
      plansDefaultCountry
      plansPlaceholder
      plansMonthlySubscription
      plansYearlySubscription
      plansPerMonthPrice
      plansPerYearPrice
      plansPromoPricingText
      plansPlanFeatures
      plansSelectText
      plansSelectLink
      plansDisclaimerTextNode {
        childMarkdownRemark {
          html
        }
      }
      plansFreeTrialBackground {
        url
        alt
        gatsbyImageData
      }
      plansFreeTrialImage {
        url
        alt
        gatsbyImageData(width: 125)
      }
      plansFreeTrialHeading
      plansFreeTrialText
      plansFreeTrialBadgeText
      plansFreeTrialLinkUrl
      plansFreeTrialFreeText
      cardCarouselTitle
      cardCarouselDescription
      cardCarouselCards {
        title
        description
        icon {
          url
          alt
          gatsbyImageData
        }
      }
      twoItemIconTextAreaLeftIcon {
        url
        alt
        gatsbyImageData
      }
      twoItemIconTextAreaLeftTitle
      twoItemIconTextAreaLeftText
      twoItemIconTextAreaLeftCtaText
      twoItemIconTextAreaLeftCtaLink
      twoItemIconTextAreaLeftCtaOpenNewTab
      twoItemIconTextAreaRightIcon {
        url
        alt
        gatsbyImageData
      }
      twoItemIconTextAreaRightTitle
      twoItemIconTextAreaRightText
      twoItemIconTextAreaRightCtaText
      twoItemIconTextAreaRightCtaLink
      twoItemIconTextAreaRightCtaOpenNewTab
      footerImage {
        url
        alt
        gatsbyImageData
      }
      footerTitle
      footerDescription
      footerPrimaryCtaText
      footerPrimaryCtaLink
      footerPrimaryCtaOpenNewTab
      footerSecondaryCtaText
      footerSecondaryCtaLink
      footerSecondaryCtaOpenNewTab
      contactSupportBannerTitle
      contactSupportBannerDescription
      contactSupportBannerCtaText
      contactSupportBannerCtaLink
      contactSupportBannerCtaOpenNewTab
      eidModalTitle
      eidModalContent
      eidModalButtonText
      variantEnabled
      variantImage {
        url
        alt
        gatsbyImageData
      }
      variantTitle
      variantSubtitle
      variantDescription
      variantBannerBackground {
        url
        alt
        gatsbyImageData
      }
      variantBannerPromoText
      variantBannerTitle
      variantBannerText
      variantBannerButtonText
      variantBannerLink
      variantCtaText
      variantCtaLink
      variantCtaOpenNewTab
      variantVideoSectionTitle
      variantVideoSectionDescription
      variantVideoSectionImage {
        url
        alt
        gatsbyImageData
      }
      variantVideoSectionVideo
      faqTitle
      faqItems {
        faqId
        title
        content
      }
    }
  }
`
